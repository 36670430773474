import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../components/loader/loader.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {RatingModule} from 'primeng/rating';
import { DropDownDirective } from './drop-down.directive';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ErrorMessageComponent } from '../components/error-message/error-message.component';
import { NumericDirective } from '../pages/numeric.directive';


export function translationInitializer() {
}

@NgModule({
  declarations: [
    LoaderComponent,
    DropDownDirective,
    PageNotFoundComponent,
    ErrorMessageComponent,
    NumericDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    RatingModule,
    NgScrollbarModule,
  ],
  exports: [
    LoaderComponent,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    DropDownDirective,
    RatingModule,
    PageNotFoundComponent,
    NgScrollbarModule,
    ErrorMessageComponent,
    NumericDirective
  ],
  providers: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
