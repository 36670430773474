import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslationService } from './translation.service';
import { Subject } from 'rxjs';
import { User } from '../authentication/user.model';


@Injectable({
  providedIn: 'root'
})
export class SharedService {


ipRoot: string = 'https://caravan.icitybackend.com/api';
ipRootImg: string = 'https://caravan.icitybackend.com/';



constructor(private translate: TranslationService,
   private http: HttpClient) {

}

errorShowMessage = new Subject<boolean>();//to show / hide error message
stringErrorMessage = new Subject<string>();//string error
headerOptionValue = new Subject<{}>();
useData: User;

  //set languakge app
  setLang(lang: string): void {
    localStorage.setItem('languageApp', lang);
    this.translate.use(lang);
  }

  //get header data
  getHeader() {
    return this.http.get(this.ipRoot + '/Menu/GetAll');
  }

  hideErrorMessage() {
    setTimeout(() => {
      this.errorShowMessage.next(false);
    }, 3000);
  }

}
