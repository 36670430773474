<app-error-message></app-error-message>

<router-outlet></router-outlet>


<ngx-spinner
    bdColor="#fff"
    size="medium"
    color="#ED6B2D"
    z-index="999999999999999999999999999999"
    [fullScreen]= "true"
    template="<img src='../assets/loader.gif' style='width: 150px'/>"  >
</ngx-spinner>