import { APP_INITIALIZER, NgModule  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { JwtModule } from "@auth0/angular-jwt";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedService } from './shared/shared.service';
import { SharedModule } from './shared/shared.module';
import { TranslationService } from './shared/translation.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './components/loader/loader.component';
import { ToastrModule } from 'ngx-toastr';
import { User } from './authentication/user.model';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';



//set english language is the main lang in app
export function setupTranslateFactory(
  service: TranslationService): Function {
  return () =>  service.use('ar');
}

export function tokenGetter() {
   const user: User = JSON.parse( localStorage.getItem('user') || '{}');
   return user?.token || null;
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    SocialLoginModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-left',
      preventDuplicates: true,
      progressBar: true,
    }),
    RouterModule.forRoot(
      [
        {path:"" , component:LoaderComponent},
      ]
    )
  ],
  providers: [SharedService,
     TranslationService,
     { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
     {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ TranslationService ],
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '731645287105-p6fn6gortp6nvc7rhbh86ur0ihkc6nqf.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('536085337695553')
          },
        ]
      } as SocialAuthServiceConfig,
    },
    {provide: LocationStrategy, useClass:  HashLocationStrategy},
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

//726090591422821
//'2945131089-v06urbiein1vm2pid9d53dun1aoat31t.apps.googleusercontent.com'


