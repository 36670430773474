import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit, OnDestroy {

  constructor(private sharedService: SharedService) { }

  errorMessage: string;
  showMessage: boolean;

  subscriptionString: Subscription;
  subscriptionBoolean: Subscription;

  ngOnInit(): void {

    //append string
   this.subscriptionString = this.sharedService.stringErrorMessage.subscribe(
      (message: string) => {
        this.errorMessage = message;
        console.log(this.errorMessage);
      }
    );

    //append show/hide
   this.subscriptionBoolean = this.sharedService.errorShowMessage.subscribe(
      (dataShow: boolean) => {
        this.showMessage = dataShow;
      }
    );
    
  }

  ngOnDestroy() {
    console.log('on Destroyyyyyyyyyyyyyyyyyy!');
    this.subscriptionString.unsubscribe();
    this.subscriptionBoolean.unsubscribe();
  }

}
